import loadScript from './loadScript'

export default function loadIntercom(appId) {

  // Can I delay loading Intercom on my site to reduce the JS load ?
  // https://www.intercom.com/help/en/articles/5053693
  // -can-i-delay-loading-intercom-on-my-site-to-reduce-the-js-load
  const ic = window.Intercom

  if (typeof ic === 'function') {
    ic('reattach_activator'),
    ic('update', { app_id: appId })
    return Promise.resolve()
  }

  const i = (...args) => i.c(args)
  i.q = []
  i.c = args => i.q.push(args)
  window.Intercom = i

  const src = `https://widget.intercom.io/widget/${appId}`
  return loadScript(src, { defer: true })
}
