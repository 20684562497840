import { $, $$, getParent, on } from '@landing/official-desktop/utils'

class Tabbox {

  constructor(selector) {
    const dom = $(selector)
    const bar = $('[data-tabs]', dom)
    this.tabs = $$('button', bar)
    this.panes = $$('[data-panes] > div', dom)
    this.bar = bar
    this.addEvents()
  }

  setActiveTab(target) {
    this.tabs.forEach(tab => tab.classList.remove('js-active'))
    target.classList.add('js-active')
  }

  setActivePane(pane) {
    this.panes.forEach(pane => pane.classList.remove('js-active'))
    pane.classList.add('js-active')
  }

  addEvents() {
    on(this.bar, 'click', event => {
      const target = getParent(event.target, 'button')
      const index = this.tabs.findIndex(tab => tab === target)
      if (index === -1) {
        return
      }
      this.setActiveTab(target)
      this.setActivePane(this.panes[index])
    })
  }
}

export default Tabbox
