import { rmProp } from '@landing/official-desktop/utils'

class Toast {

  constructor() {
    const dom = this.getToastDom()
    document.body.appendChild(dom)
    this.dom = dom
  }

  getToastDom() {
    const div = document.createElement('div')
    div.className = 'toast'
    return div
  }

  show(msg) {
    const { dom } = this
    dom.innerHTML = msg
    window.requestAnimationFrame(() => {
      dom.style.transform = 'translateY(0)'
    })
  }

  hide() {
    const { dom } = this
    window.requestAnimationFrame(() => {
      rmProp(dom.style, 'transform')
      setTimeout(() => dom.innerHTML = '', 300)
    })
  }
}

export default Toast
