import { loadIntercom } from '@landing/official-common/utils'

let INTERCOM_LOADED = false

class Intercom {

  constructor(appId) {
    this.appId = appId
  }

  get loaded() {
    return INTERCOM_LOADED
  }

  load() {
    return loadIntercom(this.appId)
      .then(() => INTERCOM_LOADED = true)
  }

  call(type, ...args) {
    return window.Intercom && window.Intercom(type, ...args)
  }

  boot() {
    return this.load()
      .then(() => {
        this.call('boot', { app_id: this.appId })
      })
  }

  show() {
    this.call('show')
  }
}

export default Intercom
