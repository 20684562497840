import { uniqueId } from '@landing/official-desktop/utils'

class Loader {

  static from(id, options = {}) {
    const div = document.createElement('div')
    div.id = id
    div.className = 'app-loader__box'
    if (options.light) {
      div.classList.add('app-loader__box--light')
    }
    div.innerHTML = `
      <div class="app-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    `
    return div
  }

  static addLoader(btn, options) {
    const id = uniqueId('loader-')
    const loader = Loader.from(id, options)
    let btnHtml = ''
    if (options.append) {
      btn.appendChild(loader)
    }
    else {
      btnHtml = btn.innerHTML.toString()
      btn.innerHTML = loader.outerHTML.toString()
    }
    btn.disabled = true
    return () => {
      const loader = document.getElementById(id)
      if (loader && options.append) {
        btn.removeChild(loader)
      }
      else if (! options.append) {
        btn.innerHTML = btnHtml
      }
      btn.disabled = false
    }
  }
}

export default Loader
