import parseEvent from '@landing/official-common/utils/parseEvent'
import isDef from '@landing/common/utils/isDef'
import isUndef from '@landing/common/utils/isUndef'
import roundX from '@landing/common/utils/roundX'

import {
  fromEvent,
  merge
} from 'rxjs'

import {
  debounceTime,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime
} from 'rxjs/operators'

import axios from 'axios'
import comma from '@superlanding/commaseparate'
import getDomPos from '@superlanding/getdompos'
import px from '@superlanding/topixel'
import toInt from '@superlanding/toint'
import compact from 'lodash.compact'
import first from 'lodash.first'
import keyBy from 'lodash.keyby'
import last from 'lodash.last'
import noop from 'lodash.noop'
import uniqueId from 'lodash.uniqueid'
import get from 'lodash.get'

import polyfill from './polyfill'
import useDrag from './useDrag'

const $ = (selector, dom = document) => dom.querySelector(selector)
const $$ = (selector, dom = document) => Array.from(dom.querySelectorAll(selector))

const getParent = (dom, t) => {
  const tag = t.toUpperCase()
  let node = dom
  while (node) {
    if (node.tagName === tag) {
      return node
    }
    node = node.parentNode
  }
  return null
}

const on = (dom, event, fn, useCapture = false) => {
  dom.addEventListener(event, fn, useCapture)
  return () => dom.removeEventListener(event, fn, useCapture)
}

const rmProp = (style, prop) => {
  if (style.removeProperty) {
    return style.removeProperty(prop)
  }
  return style.removeAttribute(prop)
}

export {
  $,
  $$,
  axios,
  comma,
  compact,
  debounceTime,
  first,
  fromEvent,
  getDomPos,
  getParent,
  isDef,
  isUndef,
  keyBy,
  last,
  map,
  merge,
  noop,
  on,
  parseEvent,
  polyfill,
  px,
  rmProp,
  roundX,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
  toInt,
  uniqueId,
  get,
  useDrag
}
