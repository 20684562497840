import { fromEvent, throttleTime } from '@landing/official-desktop/utils'

const doc = document.scrollingElement || document.documentElement
const topBtn = document.getElementById('top-btn')

const setStyle = () => {
  const { scrollTop } = doc
  topBtn.style.display = (scrollTop > (window.innerHeight * 4)) ? 'block' : 'none'
}

fromEvent(topBtn, 'click')
  .subscribe(() => {
    // https://caniuse.com/?search=scrollTo
    doc.scrollTop = 0
  })

fromEvent(window, 'scroll')
  .pipe(throttleTime(30))
  .subscribe(() => setStyle())
