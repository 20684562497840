import { $, on, noop } from '@landing/official-desktop/utils'
import Intercom from '@landing/official-desktop/models/Intercom'
import Loader from '@landing/official-desktop/models/Loader'
import { APP_ID } from '@landing/official-desktop/consts/intercom'

const intercom = new Intercom(APP_ID)
const btn = $('#customer-service-btn')

on(btn, 'click', () => {

  const off = intercom.loaded ?
    noop : Loader.addLoader(btn, { append: true })

  intercom.show()
    .catch(() => {
      alert('INTERCOM 服務掛掉了，請稍後再試')
    })
    .finally(() => off())
})

