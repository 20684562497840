// https://developers.google.com/analytics/devguides/collection/gtagjs
window.dataLayer = window.dataLayer || []

function gtag() {
  window.dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', 'G-8RTZ32JDLB')

window.gtag = gtag
