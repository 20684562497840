import {
  fromEvent,
  map,
  merge,
  noop,
  parseEvent,
  roundX,
  switchMap,
  takeUntil,
  tap
} from '../utils'

export default function useDrag(el, options = {}) {

  const downFn = options.down || noop
  const upFn = options.up || noop

  const mouseDown$ = fromEvent(el, 'mousedown')
  const mouseMove$ = fromEvent(document, 'mousemove')
  const mouseUp$ = fromEvent(document, 'mouseup')

  const touchStart$ = fromEvent(el, 'touchstart', { passive: true })
  const touchMove$ = fromEvent(document, 'touchmove', { passive: false })
  const touchEnd$ = fromEvent(document, 'touchend', { passive: true })

  const down$ = merge(mouseDown$, touchStart$)
  const move$ = merge(mouseMove$, touchMove$)
  const up$ = merge(mouseUp$, touchEnd$)

  return down$.pipe(switchMap(down => {
    const d = parseEvent(down)
    downFn(down)
    return move$.pipe(
      map(move => {
        const m = parseEvent(move)
        return { deltaX: roundX(m.x - d.x) }
      }),
      takeUntil(up$.pipe(tap(up => upFn(up))))
    )
  }))
}
