export default function loadScript(src, options = {}) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    if (options.async) {
      script.async = true
    }
    else if (options.defer) {
      script.defer = true
    }
    document.body.appendChild(script)
  })
}
